import React from 'react'
import styled from '@emotion/styled'

const Icon = ({ name, size, fill, title, ...props }) => {
  return (
    <Root>
      <img
        src={require(`../../assets/icons/${name}.svg`)}
        width={size}
        height={size}
        alt={name}
        {...props}
      />
    </Root>
  )
}

Icon.defaultProps = {
  size: 40
}

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export default Icon
